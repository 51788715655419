import React, { useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "../components/ToastContext"; // Updated import path for ToastContext

const StarRating = ({ rating, onRatingChange }) => {
  const handleClick = (index) => {
    onRatingChange(index + 1); // Ratings are 1-based
  };

  return (
    <div>
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          onClick={() => handleClick(index)}
          style={{
            cursor: "pointer",
            fontSize: "1.5em",
            color: index < rating ? "gold" : "gray",
          }}
        >
          ★
        </span>
      ))}
    </div>
  );
};

const SubmitReview = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    reviewer_name: "",
    reviewer_email: "",
    review_title: "",
    rating: 0, // Changed default rating to a number
    review_text: "",
    review_type_id: "",
    resident_status: "",
    agreed_to_terms: false,
    is_anonymous: false, // Added new field
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleRatingChange = (newRating) => {
    setFormData({
      ...formData,
      rating: newRating,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setSuccess(false);

      // Post data to API
      await api.post("/reviews/new", formData);

      // Show success toast
      showToast("Review submitted successfully! It is now waiting to be moderated.", "success");
      setSuccess(true);
      navigate('/reviews'); 
      setFormData({
        reviewer_name: "",
        reviewer_email: "",
        review_title: "",
        rating: 0,
        review_text: "",
        review_type_id: "",
        resident_status: "",
        agreed_to_terms: false,
        is_anonymous: false,
      });
    } catch (err) {
      setError(
        "An error occurred while submitting the review. Please try again."
      );
      console.log(err);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Submit a Review</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && (
        <div className="alert alert-success">
          Review submitted successfully!
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="reviewer_name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="reviewer_name"
            name="reviewer_name"
            value={formData.reviewer_name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="reviewer_email" className="form-label">
            Email <small>(Optional)</small>
          </label>
          <input
            type="email"
            className="form-control"
            id="reviewer_email"
            name="reviewer_email"
            value={formData.reviewer_email}
            onChange={handleChange}
            placeholder="Enter your email"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="review_title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="review_title"
            name="review_title"
            value={formData.review_title}
            onChange={handleChange}
            placeholder="Enter a title for your review"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="rating" className="form-label">
            Rating
          </label>
          <StarRating
            rating={formData.rating}
            onRatingChange={handleRatingChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="review_text" className="form-label">
            Comments
          </label>
          <textarea
            className="form-control"
            id="review_text"
            name="review_text"
            rows="4"
            value={formData.review_text}
            onChange={handleChange}
            placeholder="Write your comments here"
            required
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="review_type_id" className="form-label">
            Category
          </label>
          <select
            className="form-select"
            id="review_type_id"
            name="review_type_id"
            value={formData.review_type_id}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select a category
            </option>
            <option value="1">Building Management</option>
            <option value="2">Property Management</option>
            <option value="3">Building Upkeep</option>
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Current Resident?</label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="resident_status"
                id="current"
                value="Current"
                checked={formData.resident_status === "Current"}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="current">
                Current Resident
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="resident_status"
                id="former"
                value="Former"
                checked={formData.resident_status === "Former"}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="former">
                Former Resident
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="is_anonymous"
            name="is_anonymous"
            checked={formData.is_anonymous}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="is_anonymous">
            Submit as an anonymous review (Name will not be displayed publicly)
          </label>
        </div>

        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="agreed_to_terms"
            name="agreed_to_terms"
            checked={formData.agreed_to_terms}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="agreed_to_terms">
            I confirm that this review is truthful and reflects my honest
            experience.
          </label>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit Review
        </button>
      </form>
    </div>
  );
};

export default SubmitReview;
