import React, { useState, useEffect } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const ReviewsPage = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReviews, setTotalReviews] = useState(0); // Total number of reviews
  const [averageRating, setAverageRating] = useState(0); // Average rating
  const [allReviewsSummary, setAllReviewsSummary] = useState({
    total: 0,
    average: 0,
    ratingsCount: [0, 0, 0, 0, 0], // Array to store the count for each star rating
  });
  const navigate = useNavigate();

  // Fetch overall review summary (from /reviews/all)
  useEffect(() => {
    const fetchAllReviewsSummary = async () => {
      try {
        const response = await api.get("/reviews/all");
        const data = response.data;

        // Calculate the total count of reviews and average rating, excluding No Rating reviews
        const total = data.reduce((sum, review) => {
          return review.rating && review.rating > 0 ? sum + review.rating : sum; // Exclude No Rating
        }, 0);
        const totalRatings = data.filter(
          (review) => review.rating && review.rating > 0
        ).length; // Only count reviews with ratings

        const average = totalRatings > 0 ? total / totalRatings : 0;

        // Count the number of reviews for each rating (1 through 5) and count No Rating Reviews
        const ratingsCount = [0, 0, 0, 0, 0];
        let noRatingCount = 0;
        data.forEach((review) => {
          if (review.rating >= 1 && review.rating <= 5) {
            ratingsCount[review.rating - 1]++;
          } else if (review.rating === null || review.rating === 0) {
            noRatingCount++;
          }
        });

        setAllReviewsSummary({
          total: data.length || 0,
          average: average.toFixed(1), // Rounded to one decimal place
          ratingsCount,
          noRatingCount, // Add count of No Rating Reviews
        });
      } catch (error) {
        console.error("Error fetching all reviews summary:", error);
      }
    };

    fetchAllReviewsSummary();
  }, []);

  // Fetch reviews for pagination (from /reviews?page=X)
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await api.get(`/reviews?page=${currentPage}`);
        setReviews(response.data.reviews || []); // Ensure reviews is an empty array if undefined
        setTotalPages(response.data.totalPages || 1); // Default to 1 if undefined

        // Calculate average rating for the current page's reviews
        const total = response.data.reviews?.reduce(
          (sum, review) => sum + review.rating,
          0
        );
        setAverageRating(
          response.data.reviews?.length
            ? total / response.data.reviews.length
            : 0
        );
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [currentPage]);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Generate page number buttons
  const renderPageNumbers = () => {
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${
            i === currentPage ? "btn-primary" : "btn-light"
          } mx-1`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  // Function to generate a string of ★ based on the rating level
  const getStars = (rating) => {
    return "★".repeat(rating);
  };

  return (
    <div>
      <div className="container mt-4">
        <h1>Reviews</h1>
        <small>
          Read about real life experiences to get a taste of what it's like to
          live at FV By Peppers.
        </small>
        <div className="d-flex justify-content-end mt-1 gap-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate("/reviews/new")}
          >
            <strong>Submit Review</strong>
          </button>
        </div>

        {/* Display total reviews and average rating from the /reviews/all endpoint */}
        <div className="d-flex justify-content-start mt-1 gap-0 flex-column">
          {/* Display the overall reviews summary */}
          <div>
            <strong>Total Reviews: </strong>
            {allReviewsSummary.total} <br />
            <strong>Reviews with no rating: </strong>
            {allReviewsSummary.noRatingCount}
            <br />
            <strong>Average Rating: </strong>
            {allReviewsSummary.average} <br />
          </div>

          {/* Stacked rating bars for overall reviews */}
          {Array.from({ length: 5 }, (_, index) => {
            const ratingCount = allReviewsSummary.ratingsCount[index]; // Using the data from /reviews/all
            const percentage = (ratingCount / allReviewsSummary.total) * 100;

            return (
              <div
                key={index}
                className="rating-bar row align-items-center mb-0"
                style={{ marginBottom: "1px" }} // Use align-items-center to vertically center align the content
              >
                {/* Star symbols */}
                <div className="col-1">
                  {getStars(index + 1)} {/* Display the stars */}
                </div>

                {/* Progress bar container */}
                <div className="col-10">
                  <div
                    className="progress"
                    style={{
                      height: "10px", // Uniform height for all progress bars
                      backgroundColor: "#e0e0e0", // Light background for the bar
                      padding: 0,
                    }}
                  >
                    <div
                      className="progress-bar"
                      style={{
                        width: `${percentage}%`, // Set width based on the percentage
                        backgroundColor: "gold", // Set the bar color to gold
                        height: "100%", // Fill the height of the progress container
                      }}
                    ></div>
                  </div>
                </div>

                {/* Review count */}
                <div className="col-1 text-end">
                  {" "}
                  {/* Use col-2 for the review count and text-end for right alignment */}
                  {ratingCount} reviews
                </div>
              </div>
            );
          })}
        </div>
        <hr></hr>

        {/* Reviews List */}
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div className="card mt-3" key={review.id}>
              <div className="card-header">
                <h5>{review.review_title}</h5>
                <small>
                  Written on:{" "}
                  {new Date(review.review_date).toLocaleString(undefined, {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: undefined,
                    minute: undefined,
                    second: undefined,
                  })}
                </small>
                <div>
                  Reviewed on{" "}
                  {review.review_source_link ? (
                    <a href={review.review_source_link}>
                      <img
                        src={review.logo_thumbnail}
                        alt={review.name}
                        height="18px"
                        width="18px"
                      />
                    </a>
                  ) : (
                    <img
                      src={review.logo_thumbnail}
                      alt={review.name}
                      height="18px"
                      width="18px"
                    />
                  )}
                </div>{" "}
                <button
                  type="button"
                  className={`btn ${
                    review.rating ? "btn-secondary" : "btn-secondary"
                  } position-absolute top-0 end-0 m-1`}
                >
                  {review.rating === null || review.rating === 0 ? (
                    "No Rating"
                  ) : (
                    <>
                      {review.rating} {getStars(review.rating)}{" "}
                      {/* Show rating number and stars */}
                    </>
                  )}
                </button>
              </div>
              <div className="card-body">
                <p className="card-text" style={{ whiteSpace: "pre-wrap" }}>
                  {review.review_text}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No reviews available.</p>
        )}

        {/* Pagination Controls */}
        <div className="d-flex justify-content-center mt-4">
          {/* Left Arrow Button */}
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="fa fa-chevron-left"></i>
          </button>

          {/* Page Number Buttons */}
          {renderPageNumbers()}

          {/* Right Arrow Button */}
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;
