import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const IssuesPage = () => {
  const [issues, setIssues] = useState([]);
  const [page, setPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const [openCount, setOpenCount] = useState(0); // Track open issues
  const [closedCount, setClosedCount] = useState(0); // Track closed issues
  const [totalIssues, setTotalIssues] = useState(0); // Track total number of issues
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [error, setError] = useState(null); // To store error messages
  const [success, setSuccess] = useState(false); // To track submission success
  const navigate = useNavigate();

  const [newIssue, setNewIssue] = useState({
    title: "",
    category: "",
    otherCategory: "",
    startDate: "",
    description: "",
    name: "",
    email: "",
    attachment: null,
  });

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await api.get(`/issues?page=${page}&limit=10`);
        setIssues(response.data.issues);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        console.error("Error fetching paginated issues:", err);
      }
    };

    fetchIssues();
  }, [page]);

  useEffect(() => {
    const fetchAllIssues = async () => {
      try {
        const response = await api.get("/issues/all");
        setTotalIssues(response.data.length);

        const openIssues = response.data.filter(
          (issue) => issue.progress !== "Resolved"
        ).length;
        const closedIssues = response.data.filter(
          (issue) => issue.progress === "Resolved"
        ).length;

        setOpenCount(openIssues);
        setClosedCount(closedIssues);
      } catch (err) {
        console.error("Error fetching all issues:", err);
      }
    };

    fetchAllIssues();
  }, []);

  const getStatusColor = (progress) => {
    switch (progress) {
      case "Open":
        return "btn-danger";
      case "In Progress":
        return "btn-primary";
      case "Resolved":
        return "btn-success";
      default:
        return "btn-secondary";
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIssue({ ...newIssue, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "message/rfc822",
    ];
    if (file) {
      setNewIssue((prevIssue) => ({
        ...prevIssue,
        attachment: file, // Ensure the file is correctly set here
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setSuccess(false);
  
      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append("title", newIssue.title);
      formData.append("category", newIssue.category);
      formData.append("otherCategory", newIssue.otherCategory);
      formData.append("startDate", newIssue.startDate);
      formData.append("description", newIssue.description);
      formData.append("name", newIssue.name);
      formData.append("email", newIssue.email);
  
      // If a file is selected, append it to FormData
      if (newIssue.attachment) {
        formData.append("attachment", newIssue.attachment);
      }
  
      // Send FormData to the backend
      await api.post("/issues/new", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Reset form on success
      setSuccess(true);
      setNewIssue({
        title: "",
        category: "",
        otherCategory: "",
        startDate: "",
        description: "",
        name: "",
        email: "",
        attachment: null,  // Clear attachment state
      });
  
      setModalOpen(false); // Close the modal
    } catch (err) {
      setError("An error occurred while submitting the issue. Please try again.");
    }
  };  

  const renderPageNumbers = () => {
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${i === page ? "btn-primary" : "btn-light"} mx-1`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div>
      <div className="container mt-4">
        <h1>Issue Tracker</h1>
        <small>Select an issue to see more information. These issues are sourced by residents & official emails from building & property managers sent to tenants.</small>

        <div className="d-flex justify-content-end mt-1 gap-2">
          <button type="button" className="btn btn-danger">
            <strong>Open Issues</strong>{" "}
            <span className="badge bg-secondary">{openCount}</span>
          </button>
          <button type="button" className="btn btn-success">
            <strong>Closed Issues</strong>{" "}
            <span className="badge bg-secondary">{closedCount}</span>
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate("/issues/new")}
          >
            <strong>Report New Issue</strong>
          </button>
        </div>

        <ul className="list-group mt-3">
          {issues.map((issue) => (
            <a
              href={`/issues/${issue.id}/updates`}
              className="text-decoration-none"
              key={issue.id}
            >
              <li className="list-group-item position-relative">
                <button
                  type="button"
                  className={`btn ${getStatusColor(
                    issue.progress
                  )} position-absolute top-0 end-0 m-2`}
                >
                  {issue.progress}
                </button>

                <h3>{issue.title}</h3>
                <p style={{ whiteSpace: "pre-wrap" }}>{issue.description}</p>
              </li>
            </a>
          ))}
        </ul>

        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <i className="fa fa-chevron-left"></i>
          </button>
          {renderPageNumbers()}
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* Modal Header */}
              <div className="modal-header">
                <h5 className="modal-title">Report New Issue</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalOpen(false)}
                ></button>
              </div>

              {/* Modal Form */}
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  {/* Issue Title */}
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Issue Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      value={newIssue.title}
                      onChange={handleInputChange}
                      placeholder="Enter issue title"
                      required
                    />
                  </div>

                  {/* Issue Category */}
                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Issue Category
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      name="category"
                      value={newIssue.category}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select a category</option>
                      <option value="Building Management">
                        Building Management
                      </option>
                      <option value="Property Management">
                        Property Management
                      </option>
                      <option value="Electrical">Electrical</option>
                      <option value="Plumbing">Plumbing</option>
                      <option value="Elevator">Elevator</option>
                      <option value="Intercom">Intercom</option>
                      <option value="General">General</option>
                      <option value="Aircon">Aircon</option>
                      <option value="Garage">Garage</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {/* Additional Category Input (if "Other" selected) */}
                  {newIssue.category === "Other" && (
                    <div className="mb-3">
                      <label htmlFor="otherCategory" className="form-label">
                        Other Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="otherCategory"
                        name="otherCategory"
                        value={newIssue.otherCategory || ""}
                        onChange={handleInputChange}
                        placeholder="Enter other category"
                        required
                      />
                    </div>
                  )}

                  {/* Start Date */}
                  <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                      Issue Start Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      name="startDate"
                      value={newIssue.startDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  {/* Description */}
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Issue Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows="3"
                      value={newIssue.description}
                      onChange={handleInputChange}
                      placeholder="Describe the issue"
                      required
                    ></textarea>
                  </div>

                  {/* Name */}
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Your Name - <small>Name will not be published.</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={newIssue.name}
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                      required
                    />
                  </div>

                  {/* Email */}
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Your Email - <small>Optional</small>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={newIssue.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                    />
                  </div>

                  {/* File Attachment */}
                  <div className="mb-3">
                    <label htmlFor="attachment" className="form-label">
                      Supporting Documentation
                      <br />
                      <small>
                        Any uploads will be confidential and are for moderation
                        purposes only.
                      </small>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="fileInput"
                      accept=".pdf, .png, .jpg, .jpeg, .eml"
                      onChange={handleFileChange}
                    />
                    <small className="text-muted">
                      Allowed file types: PDF, PNG, JPEG, EML.
                    </small>
                  </div>
                </div>

                {/* Modal Footer */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssuesPage;
