import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-2">
      <b>Disclaimer:</b> This website is independently operated and is not
      affiliated with, endorsed by, or managed by FV By Peppers, Accor Hotels,
      or any associated entities. All content, reviews, and opinions expressed
      here are based on residents' personal experiences.
    </footer>
  );
};

export default Footer;
