// src/context/ToastContext.js
import React, { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

// Create the context
const ToastContext = createContext();

// Custom hook to use the Toast context
export const useToast = () => useContext(ToastContext);

// ToastProvider component to wrap the app with the Toast context
export const ToastProvider = ({ children }) => {
  const showToast = (message, type = 'success') => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message);
    } else {
      toast.info(message);
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
    </ToastContext.Provider>
  );
};
