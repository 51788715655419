import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify"; // Proper spacing
import "react-toastify/dist/ReactToastify.css"; // Make sure to import the CSS
import { ToastProvider } from "./components/ToastContext";
//import AdminPage from "./pages/AdminPage";
import HomePage from "./pages/HomePage";
import IssuesPage from "./pages/IssuesPage";
import NewIssuePage from "./pages/NewIssuePage";
import IssuesUpdatesPage from "./pages/IssuesUpdatesPage";
import RecurringIssuesPage from "./pages/RecurringIssues";
import RecurringIssuesDetailsPage from "./pages/RecurringIssuesDetailsPage";
import ReviewsPage from "./pages/ReviewsPage";
import NewReviewPage from "./pages/NewReviewPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; // Import Footer component

const App = () => {
  return (
    <ToastProvider>
      {" "}
      {/* Wrap the app with ToastProvider */}
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Navbar />
          <ToastContainer position="top-right" autoClose={5000} />
          <main className="flex-grow-1">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/issues" element={<IssuesPage />} />
              <Route path="/issues/new" element={<NewIssuePage />} />
              <Route
                path="/recurring-issues"
                element={<RecurringIssuesPage />}
              />
              <Route
                path="/issues/:id/updates"
                element={<IssuesUpdatesPage />}
              />
              <Route
                path="/recurring-issues/:id/details"
                element={<RecurringIssuesDetailsPage />}
              />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/reviews/new" element={<NewReviewPage />} />
              {/*<Route path="/admin" element={<AdminPage />} />*/}
              {/* Add more routes as needed */}
            </Routes>
          </main>
          <Footer className="mt-auto" /> {/* Footer with mt-auto class */}
        </div>
      </Router>
    </ToastProvider>
  );
};

export default App;
