import React, { useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import { useToast } from '../components/ToastContext';  // Updated import path for ToastContext

const NewIssuePage = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();  // Get showToast from the context
  const [newIssue, setNewIssue] = useState({
    title: "",
    category: "",
    otherCategory: "",
    startDate: "",
    description: "",
    name: "",
    email: "",
    attachment: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIssue({ ...newIssue, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "message/rfc822",
    ];
    if (file) {
      setNewIssue((prevIssue) => ({
        ...prevIssue,
        attachment: file, // Ensure the file is correctly set here
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setSuccess(false);

      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append("title", newIssue.title);
      formData.append("category", newIssue.category);
      formData.append("otherCategory", newIssue.otherCategory);
      formData.append("startDate", newIssue.startDate);
      formData.append("description", newIssue.description);
      formData.append("name", newIssue.name);
      formData.append("email", newIssue.email);

      // If a file is selected, append it to FormData
      if (newIssue.attachment) {
        formData.append("attachment", newIssue.attachment);
      }

      // Send FormData to the backend
      await api.post("/issues/new", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Show success notification and redirect to the issues page
      showToast("Your issue has been reported successfully!", "success"); // Use showToast instead of toast.success
      setSuccess(true);
      
      // Redirect after a short delay to allow the toast to show
      navigate('/issues'); 
    } catch (err) {
      // Show error notification
      showToast("An error occurred while submitting the issue. Please try again. If the error persists please email contact@lifeatthevalley.com.", "error");  // Use showToast instead of toast.error
      setError("An error occurred while submitting the issue. Please try again.");
    }
  };

  return (
    <div className="container mt-4">
      <h1>Report New Issue</h1>

      {error && <div className="alert alert-danger">{error}</div>}
      {success && (
        <div className="alert alert-success">Issue reported successfully!</div>
      )}

      <form onSubmit={handleSubmit}>
        {/* Issue Title */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Issue Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={newIssue.title}
            onChange={handleInputChange}
            placeholder="Enter issue title"
            required
          />
        </div>

        {/* Issue Category */}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Issue Category
          </label>
          <select
            className="form-select"
            id="category"
            name="category"
            value={newIssue.category}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a category</option>
            <option value="Building Management">Building Management</option>
            <option value="Property Management">Property Management</option>
            <option value="Electrical">Electrical</option>
            <option value="Plumbing">Plumbing</option>
            <option value="Elevator">Elevator</option>
            <option value="Intercom">Intercom</option>
            <option value="General">General</option>
            <option value="Aircon">Aircon</option>
            <option value="Garage">Garage</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Additional Category Input (if "Other" selected) */}
        {newIssue.category === "Other" && (
          <div className="mb-3">
            <label htmlFor="otherCategory" className="form-label">
              Other Category
            </label>
            <input
              type="text"
              className="form-control"
              id="otherCategory"
              name="otherCategory"
              value={newIssue.otherCategory || ""}
              onChange={handleInputChange}
              placeholder="Enter other category"
              required
            />
          </div>
        )}

        {/* Start Date */}
        <div className="mb-3">
          <label htmlFor="startDate" className="form-label">
            Issue Start Date
          </label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            name="startDate"
            value={newIssue.startDate}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Issue Description
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="3"
            value={newIssue.description}
            onChange={handleInputChange}
            placeholder="Describe the issue"
            required
          ></textarea>
        </div>

        {/* Name */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Your Name - <small>Name will not be published.</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={newIssue.name}
            onChange={handleInputChange}
            placeholder="Enter your name"
            required
          />
        </div>

        {/* Email */}
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Your Email - <small>Optional</small>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={newIssue.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
          />
        </div>

        {/* File Attachment */}
        <div className="mb-3">
          <label htmlFor="attachment" className="form-label">
            Supporting Documentation
            <br />
            <small>
              Any uploads will be confidential and are for moderation purposes
              only.
            </small>
          </label>
          <input
            className="form-control"
            type="file"
            id="fileInput"
            accept=".pdf, .png, .jpg, .jpeg, .eml"
            onChange={handleFileChange}
          />
          <small className="text-muted">
            Allowed file types: PDF, PNG, JPEG, EML.
          </small>
        </div>

        {/* Submit Button */}
        <div className="mb-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>

      {/* Notification Container */}
      {/*<ToastContainer />*/}
    </div>
  );
};

export default NewIssuePage;
