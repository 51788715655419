import React from 'react';
import { Link } from 'react-router-dom'; // For routing with React Router

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/">Life at The Valley</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Issues
                        </a>
                        <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" to="/issues">Current Issues</Link></li>
                            <li><Link className="dropdown-item" to="/recurring-issues">Recurring Issues</Link></li>
                            
                            <li><Link className="dropdown-item" to="/issues/new">Report New Issue</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/reviews">Reviews</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
