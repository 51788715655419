import React, { useState, useEffect } from "react";
import api from "../services/api";

const IssuesPage = () => {
  const [issues, setIssues] = useState([]);
  const [page, setPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const [openCount, setOpenCount] = useState(0); // Track open issues
  const [closedCount, setClosedCount] = useState(0); // Track closed issues
  const [totalIssues, setTotalIssues] = useState(0); // Track total number of issues

  // Function to fetch issues for pagination
  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await api.get(`/issues/r?page=${page}&limit=10`);
        setIssues(response.data.issues);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        console.error("Error fetching paginated issues:", err);
      }
    };

    fetchIssues();
  }, [page]); // Re-fetch when page changes

  // Function to fetch all issues for counting open and closed issues
  useEffect(() => {
    const fetchAllIssues = async () => {
      try {
        const response = await api.get("/issues/r/all");
        setTotalIssues(response.data.length); // Assuming response.data is an array of all issues

        // Count the open and closed issues
        const openIssues = response.data.filter(
          (issue) => issue.progress !== "Resolved"
        ).length;
        const closedIssues = response.data.filter(
          (issue) => issue.progress === "Resolved"
        ).length;

        setOpenCount(openIssues);
        setClosedCount(closedIssues);
      } catch (err) {
        console.error("Error fetching all issues:", err);
      }
    };

    fetchAllIssues();
  }, []); // Run this only once when the component is mounted

  // Function to determine the button color based on issue progress
  const getStatusColor = (progress) => {
    switch (progress) {
      case "Open":
        return "btn-danger"; // Red for Open
      case "In Progress":
        return "btn-primary"; // Blue for In Progress
      case "Resolved":
        return "btn-success"; // Green for Resolved (Closed)
      default:
        return "btn-secondary"; // Default color for unknown progress
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Generate a list of page numbers to display in the pagination
  const renderPageNumbers = () => {
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${i === page ? "btn-primary" : "btn-light"} mx-1`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div>
      <div className="container mt-4">
        <h1>Recurring Issues</h1>
        <p>
          Below is a list of recurring issues that are caused usually by poor
          maintenance, poor property management or building defects. These are
          sourced by residents <a href="/issues/new">reporting them here</a>, or from social media.
        </p>
        <small>Click on an item below to see further information.</small>

        {/* Recurring Issues List */}
        <ul className="list-group mt-3">
          {issues.map((issue) => (
            <a
              href={`/recurring-issues/${issue.id}/details`}
              className="text-decoration-none"
              key={issue.id}
            >
              <li className="list-group-item position-relative">
                {/* Button indicating issue status, positioned in the top-right corner */}
                <button
                  type="button"
                  className={`btn ${getStatusColor(
                    issue.progress
                  )} position-absolute top-0 end-0 m-2`}
                >
                  {issue.progress}
                </button>

                <h3>{issue.title}</h3>
                {/*<p>{issue.description}</p>*/}
              </li>
            </a>
          ))}
        </ul>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-center mt-4">
          {/* Left Arrow Button */}
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <i className="fa fa-chevron-left"></i> {/* Left arrow */}
          </button>

          {/* Page Number Buttons */}
          {renderPageNumbers()}

          {/* Right Arrow Button */}
          <button
            className="btn btn-secondary"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <i className="fa fa-chevron-right"></i> {/* Right arrow */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IssuesPage;
