import React from "react";

const HomePage = () => {
  return (
    <div>
      {/* Banner Section with Background Image */}
      {/*<div
        className="banner-container"
        style={{
          backgroundImage:
            "url(https://scontent.fbne11-1.fna.fbcdn.net/v/t39.30808-6/271588074_1239525223224111_693433224526093343_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=2285d6&_nc_ohc=4RC3JparsRsQ7kNvgGJDsCJ&_nc_zt=23&_nc_ht=scontent.fbne11-1.fna&_nc_gid=A0_AxbII8vWb5X3eUo_YOja&oh=00_AYDe2N7efNvmPxSN_1vUodfSCw1Qu-nTRknsA_qv5vh2vg&oe=675F9040)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "200px", // Adjust height for your banner
          borderRadius: "8px",
        }}
      >*}
      </div>

      {/* Page Content */}
      <div class="container mt-4">
        <h1>Welcome to Life at The Valley</h1>
        <p>
          This website has been created to provide a platform that sheds light
          on the ongoing challenges faced by residents of the FV By Peppers building complex in Fortitude Valley (The Valley).
          Our mission is simple:{" "}
          <strong>
            to expose the reality of living here and offer transparency
          </strong>{" "}
          for both current and potential residents, as well as a voice for those
          who feel unheard.
        </p>

        <p>
          This website serves as a detailed record of the issues that have
          plagued FV Peppers, from poorly maintained facilities to unresolved
          complaints and subpar management practices that impact daily living.
          Through our <a href="/issues">Issue Tracker</a>, we document
          widespread issues and provide progress updates that we receive from
          the property and building management teams. This allows the community
          to see how frequently these issues occur and the ongoing struggles
          that persist despite repeated requests for action.
        </p>

        <p>
          Our goal is not just to document these issues but to{" "}
          <strong>bring attention to the systemic problems</strong> that have
          been allowed to fester for too long. We believe that transparency and
          open dialogue are the first steps toward meaningful change. By
          highlighting these shortcomings, we hope to encourage better
          management, greater accountability, and ultimately, a better living
          experience for everyone at FV Peppers.
        </p>

        <p>
          If you're currently living here or considering making it your home,
          this website is your resource to understand the reality of living in
          this complex.
        </p>

        <p>
          We encourage you to share your own stories, track unresolved issues,
          and help us work toward a safer and more enjoyable environment for
          all.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
